import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Button, Col, Grid, Layout } from 'antd';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { setCookie } from 'nookies';
import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { GetBrandSettingFromRecoil } from '../../recoil/brandsetting';
import { GetVideoIdFromRecoil } from '../../recoil/playlist';
import { privacyTypeState } from '../../recoil/privacyType';
import { getPrivacyType } from '../../utils/functions/confidentiality';
import { getContent, handleSideBar } from '../../utils/functions/contentParams';
import { loadMatomo } from '../../utils/functions/matomo';
import { getCurrentItemFromPlaylist } from '../../utils/functions/player';
import useInterval from '../../utils/hooks/useInterval';
import useWindowSize from '../../utils/hooks/useWindowSize';
import { useCheckSession } from '../../utils/store/confidentiality';
import Header from '../Header';
import Poll from '../Poll';
import RGPDModal from '../RGPDModal';
import TabsPlayer from '../Tabs';
import FooterImg from '../../public/footer.png';
import Footer from '../Footer';

const VideoPlayer = dynamic(() => import('../Player/player2.js'), {
  ssr: false
});
const Description = dynamic(() => import('../Description'), { ssr: false });

const { Content, Sider } = Layout;
const { useBreakpoint } = Grid;

const Main = function ({ live, media, playlist, isEmbed, webTvId }) {
  const { content, contentType } = getContent(media, live, playlist);
  const { shouldShowPoll, shouldShowSideBar, sidebarHasContent } =
    handleSideBar(content, contentType);
  const [isMuted, setIsMuted] = useState(false);
  const [disableAnalytic, setDisableAnalytic] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [height, setHeight] = useState(0);
  const [title, setTitle] = useState();
  const [videoLanguage, setVideoLanguage] = useState();
  const [layoutStyle, setLayoutStyle] = useState();
  const [pollId, setPollId] = useState();
  const [multiSession, setMultiSession] = useState(false);
  const screens = useBreakpoint();
  const router = useRouter();

  const currentPlaylistContentId = GetVideoIdFromRecoil();
  const { pageTheme, videoTitle } = GetBrandSettingFromRecoil();
  const checkSession = useCheckSession();
  const [privacy, setPrivacy] = useRecoilState(privacyTypeState);

  const { width: windowWidth } = useWindowSize();

  useEffect(() => {
    const privacity = getPrivacyType({ content });
    setPrivacy(privacity);
  }, [content]);

  useInterval(async () => {
    if (privacy !== 'inscription' && privacy !== 'private') return;
    setMultiSession((await checkSession()).data);
  }, 1000 * 60 * 5);

  useEffect(() => {
    if (multiSession) {
      setCookie(null, 'contentToken', '', {});
      router.reload();
    }
  }, [multiSession]);

  useEffect(() => {
    if (shouldShowSideBar && sidebarHasContent) {
      setCollapsed(false);
    } else {
      setCollapsed(true);
    }
  }, [shouldShowSideBar, webTvId, screens]);

  useEffect(() => {
    if (typeof window === 'undefined' || disableAnalytic) return;
    loadMatomo();
  }, [disableAnalytic]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      window.dispatchEvent(new Event('scroll'));
    }, 500);
    return () => clearTimeout(timeout);
  }, [collapsed]);

  useEffect(() => {
    if (webTvId) return;
    if (live?.status === 'Ended' && live?.hasReplay && live?.replay?.id) {
      setShouldRedirect(true);
      // TODO prefetech the next router page with <Link>
      const timeout = setTimeout(() => {
        if (isEmbed) {
          window.location = `/embed/media/${live.replay.id}`;
        } else {
          window.location = `/media/${live.replay.id}`;
        }
      }, 5000);
      return () => clearTimeout(timeout);
    }
  }, [live, isEmbed, router, webTvId]);

  function setHeaderHeight() {
    const breadcrumb = document.getElementById('breadcrumb');
    if (breadcrumb) {
      const breadCrumbBounding = breadcrumb.getBoundingClientRect();
      const breadcrumbVisible =
        breadCrumbBounding.height + breadCrumbBounding.top;
      setHeight(breadcrumbVisible);
    } else {
      const header = document.getElementById('header');
      if (header) {
        const headerBounding = header.getBoundingClientRect();
        const headerHeight = headerBounding.height + headerBounding.top;
        setHeight(headerHeight < 0 ? 0 : headerHeight);
      }
    }
  }

  useEffect(() => {
    function watchScroll() {
      window.addEventListener('scroll', setHeaderHeight);
    }
    watchScroll();
    return () => {
      window.removeEventListener('scroll', setHeaderHeight);
    };
  });

  // set the video title because there is some logic to do with playlist
  useEffect(() => {
    if (!content || !contentType) return;
    if (contentType !== 'playlist') {
      setTitle(videoTitle);
      setPollId(content?.live?.poll?.id || content?.poll?.id);
    } else {
      const item = getCurrentItemFromPlaylist(
        content,
        currentPlaylistContentId
      );
      setTitle(item?.pageSetting?.videoTitle);
      setVideoLanguage(item?.language);
      setPollId(item?.poll?.id);
    }
  }, [content, contentType, videoTitle, currentPlaylistContentId]);

  useEffect(() => {
    if (router.query.disableAnalytic) {
      setDisableAnalytic(true);
    } else {
      content?.disableAnalytic && setDisableAnalytic(content?.disableAnalytic);
    }
  }, [content]);

  useEffect(() => {
    const styleTmp = {
      overflow: screens.sm ? 'inherit' : 'hidden',
      minHeight: screens.sm ? '100vh' : 'inherit'
    };
    if (!screens.sm) {
      if (CSS.supports('max-height', '100svh')) {
        styleTmp.height = '100svh';
      } else {
        styleTmp.height = '100vh';
      }
    }
    setLayoutStyle(styleTmp);
  }, [screens]);

  // MEDIAS & PLAYLISTS & LIVE
  return (
    <Layout
      style={{
        width: '100%',
        maxWidth: 1200,
        margin: "auto",
        // height: "100vh",
        // ...layoutStyle
      }}
      data-cy="layout"
    >
      <Footer />
      
      <Layout
        style={{
          width: '100%',
          position: 'relative',
          margin: 0
        }}
      >
        <Content>
          <div style={{ position: 'relative', overflow: 'hidden' }}>
            {pollId && (
              <div
                style={{
                  display:
                    !screens.sm || (!shouldShowPoll && contentType === 'media')
                      ? 'none'
                      : 'block'
                }}
              >
                <Poll
                  pollId={pollId}
                  isMuted={isMuted}
                  contentType={contentType}
                />
              </div>
            )}

            <VideoPlayer
              media={media}
              live={live}
              playlist={playlist}
              isEmbed={isEmbed}
              collapsed={collapsed}
              shouldRedirect={shouldRedirect}
              disableAnalytic={disableAnalytic}
              isMuted={isMuted}
              setIsMuted={setIsMuted}
            />
          </div>
          {/* {!isEmbed && (
            <Description
              title={videoTitle}
              language={videoLanguage}
              playlist={playlist}
              collapsed
            />
          )} */}
          {/* {!screens.sm && sidebarHasContent && (
            <Col span={24} style={{ position: 'relative', overflow: 'hidden' }}>
              <TabsPlayer
                isEmbed={isEmbed}
                media={media}
                live={live}
                playlist={playlist}
                webTvId={webTvId}
                pollId={pollId}
                title={videoTitle}
                isMuted={isMuted}
              />
            </Col>
          )} */}
           
        </Content>
        
        {sidebarHasContent && screens.sm && (
          <Sider
            trigger={null}
            collapsible
            collapsed={collapsed}
            collapsedWidth={0}
            width={Math.ceil(windowWidth / 3)}
            theme={pageTheme}
            style={{
              maxHeight: screens.sm
                ? `calc(${height > 0 ? `100vh - ${height}px` : '100vh'})`
                : 'initial',
              position: 'sticky',
              top: 0,
              zIndex: 111,
              boxShadow: webTvId
                ? ''
                : 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px'
            }}
          >
            {sidebarHasContent && screens.sm && (
              <Button
                type="link"
                style={{
                  fontSize: ' 1.6em',
                  top: 0,
                  left: collapsed ? -60 : 0,
                  position: 'absolute',
                  zIndex: 1000,
                  width: 50,
                  height: 50,
                  transition: 'all .3s ease-in-out'
                }}
                data-cy="sidebar-trigger"
                onClick={() => setCollapsed(!collapsed)}
              >
                {collapsed ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
              </Button>
            )}
            <TabsPlayer
              isEmbed={isEmbed}
              media={media}
              live={live}
              playlist={playlist}
              webTvId={webTvId}
              title={title}
              isMuted={isMuted}
            />
          </Sider>
        )}
    
      </Layout>
      {!isEmbed && !webTvId && <Header />}
      {/* <footer
      style={{
        width: '100%',
        maxWidth: 1200,
      }}>
        <div
          style={{
            width: '100%',
            maxWidth: 1200,
          }}
        >

        <img src={FooterImg.src} alt="logo" />
        </div>
      </footer> */}
      <RGPDModal />
    </Layout>
  );
};

export default Main;
