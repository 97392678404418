import { Layout, Tooltip, Typography } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import React from 'react';
import { getFontSize, getMaxWidth } from '../../utils/functions/header';

import Style from './style';
import { GetBrandSettingFromRecoil } from '../../recoil/brandsetting';
import Bandeau from '../../public/header.png';
const { Header: AntHeader } = Layout;
const { Paragraph } = Typography;
const HeaderDropdown = dynamic(() => import('./HeaderDropdown'));

const Header = function ({ animatorPage }) {
  const { t } = useTranslation('common');
  const screens = useBreakpoint();
  const { accentColor, pageTitle, pageLogo, pageTheme } =
    GetBrandSettingFromRecoil();
  return (
    <AntHeader
      style={{
        padding: 0,
      }}
      id="header"
    >
      <div
        style={{
          background: 'rgb(12 56 112)',
          textAlign: 'end',
          margin: 'auto',
          padding:'15px',
          fontSize: '18px',
          color: 'white',

        }}  
      >
        Bonne année à tous !
      </div>
      {/* <div className="headerContainer" data-cy="header">
        <img src={Bandeau.src} alt="Logo" />
        <HeaderDropdown />
      </div> */}
      {/* TODO: moove styles bellow in component */}
      <Style screens={screens} />
      <style>{`
        .mobileMenuContainer {
          display: flex;
          justify-content: space-between;
          padding: 16px;
        }
        .open-menu {
          padding-right: 12px;
        }
        .open-menu:hover {
          color: ${accentColor};
        }
      `}</style>
      <style jsx global>{`
        .ant-dropdown-menu-item {
          padding: ${screens.sm ? '8px 16px' : '12px 20px'};
          font-size: ${screens.sm ? '14px' : '16px'};
          background-color: ${pageTheme === 'dark'
            ? '#2f3237'
            : 'rgba(255,255,255,0.85)'};
          color: ${pageTheme === 'dark' ? 'rgba(255,255,255,0.85)' : '#000'};
        }
        .ant-dropdown-menu {
          background-color: ${pageTheme === 'dark'
            ? '#2f3237'
            : 'rgba(255,255,255,0.85)'} !important;
          color: ${pageTheme === 'dark' ? 'rgba(255,255,255,0.85)' : '#000'};
        }
        .ant-dropdown-menu-item-active {
          background-color: ${pageTheme === 'dark'
            ? '#1d1e20'
            : 'rgba(255,255,255,0.85)'} !important;
          color: ${pageTheme === 'dark' ? 'rgba(255,255,255,0.85)' : '#000'};
        }
        // .ant-dropdown-arrow {
        //   background: ${pageTheme === 'dark'
          ? '#2f3237'
          : 'rgba(255,255,255,0.85)'} !important;
        // }
        .ant-dropdown-arrow::before {
          background: ${pageTheme === 'dark'
            ? '#2f3237'
            : 'rgba(255,255,255,0.85)'} !important;
        }
      `}</style>
    </AntHeader>
  );
};

export default Header;
