import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { useEffect, useState } from 'react';

const useTabHeight = () => {
  const [tabHeight, setTabHeigth] = useState(0);
  const [discussionFeedHeight, setDiscussionFeedHeight] = useState(0);
  const [discussionFormHeight, setDiscussionFormHeight] = useState(0);

  const screens = useBreakpoint();

  const handleResize = () => {
    const player = document.getElementById('containerFluid');
    const header = document.getElementsByClassName('ant-layout-header');
    const tabsHeader = document.getElementsByClassName('ant-tabs-nav');
    const description = document.getElementById('description');
    const discussionForm = document.getElementById('discussionForm');

    let headerHeight = 0;
    if (header.length) {
      const { height, y } = header[0].getBoundingClientRect();
      headerHeight = height + y > 0 ? height + y : 0;
    }

    const descriptionHeight = description ? description.clientHeight : 0;    

    let topScreenheight = headerHeight + tabsHeader[0]?.clientHeight;

    if (!screens.sm) {
      if (player) {
        topScreenheight += player.clientHeight;
      }
      topScreenheight += descriptionHeight;
    }

    const windowHeight = window.visualViewport.height;

    const tabHeightTmp = windowHeight - topScreenheight;
    const discussionFeedHeightTmp =
      windowHeight - (topScreenheight + (discussionForm?.clientHeight || 0));

    if (isNaN(tabHeightTmp)) return;
    setTabHeigth(tabHeightTmp);
    setDiscussionFeedHeight(discussionFeedHeightTmp);
    setDiscussionFormHeight(discussionForm?.clientHeight || 0);
  };

  // ------------------------ INTERFACE --------------------//
  // check the size of the window and set the height of the div inside the tabs
  useEffect(() => {
    setTimeout(() => {
      handleResize();
    }, 1000);

    // Add event listener
    ['resize', 'resizeUseTabHeight'].forEach((event) => {
      window.addEventListener(event, handleResize);
    });

    return () => {
      ['resize', 'resizeUseTabHeight'].forEach((event) => {
        window.removeEventListener(event, handleResize);
      });
    };
  }, []);

  return {
    tabHeight: tabHeight > 0 ? `${tabHeight}px` : '100%',
    discussionFeedHeight:
      discussionFeedHeight > 0 ? `${discussionFeedHeight}px` : '100%',
    discussionFormHeight: `${discussionFormHeight}px`
  };
};
export default useTabHeight;
