import { useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { brandSettingState } from '../../recoil/brandsetting';
import { liveIdToUpdateState } from '../../recoil/playlist';
import Theme from '../../theme';
import { getContentParams } from '../../utils/functions/contentParams';
import useHeaders from '../../utils/hooks/useHeaders';
import { GET_UPDATED_LIVE } from '../../utils/subscription/live';
import { GET_UPDATED_LIVE_PLAYLIST } from '../../utils/subscription/playlist';
import Confidentiality from '../Confidentiality';
import Head from '../Head';
import LoaderSpin from '../LoaderSpin';
import Main from '../Main';

import { useTranslation } from 'next-i18next';
import { getBrandSettingType } from '../../utils/functions/brandSetting';
import CustomError from '../CustomError';
import Custom404 from '../../pages/404';
import useShouldBlockAccess from '../../utils/hooks/useShouldBlockAccess';

const Page = function ({
  contentFromServer,
  errorFromServer,
  isEmbed = false,
  liveId = "clcogr3w3004gm5012amb9c8w"
}) {
  const [content, setContent] = useState(contentFromServer);
  const [error, setError] = useState();
  const [brandSetting, setBrandSetting] = useRecoilState(brandSettingState);

  const router = useRouter();
  const { t } = useTranslation();
  const restrictedDomains =
    content?.embedSetting?.restrictedDomains?.map((domain) => domain.domain) ||
    error?.restrictedDomains ||
    [];
  const privacyType = content?.embedSetting?.privacyType || error?.embedPrivacy || 'public';
  const shouldBlockAccess = useShouldBlockAccess(isEmbed, privacyType, restrictedDomains);

  // https://nextjs.org/docs/routing/dynamic-routes#caveats
  // to note, while next is re-hydrating, query from router is empty
  const {mediaId} = router.query;
  const playlistId = null;
  const noContent = !mediaId && !liveId && !playlistId && !error;

  const { query, contentId, contentType } = getContentParams(
    mediaId,
    liveId,
    playlistId
  );

  // re-hydrate next with new data
  const {
    data,
    error: apolloError,
    subscribeToMore
  } = useQuery(query, {
    variables: {
      mediaId,
      liveId,
      playlistId
    },
    context: {
      headers: useHeaders()
    },
    skip: noContent
  });

  const setRecoilLiveIdToUpdateState = useSetRecoilState(liveIdToUpdateState);
  // fonction qui retourn pagesetting ou embedsetting selon si c'est un embed ou non et si il y a des pagesetting ou non
  useEffect(() => {
    setBrandSetting((prev) => ({
      ...prev,
      ...getBrandSettingType(content, isEmbed)
    }));
  }, [content]);

  // LIVE SUBSCRIPTIONS
  useEffect(() => {
    if (!subscribeToMore || !liveId || noContent || router.isFallback) return;
    // the try catch is to avoid error with next.js fast refresh
    // https://github.com/apollographql/apollo-client/issues/6437
    try {
      subscribeToMore({
        document: GET_UPDATED_LIVE,
        variables: {
          id: liveId
        },
        updateQuery: (prev, { subscriptionData }) => {
          console.log('subscriptionData', subscriptionData);
          if (!subscriptionData) return prev;
          return {
            ...prev,
            live: { ...prev.live, ...subscriptionData.data.live }
          };
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, [subscribeToMore]);

  // PLAYLIST SUBSCRIPTIONS
  useEffect(() => {
    if (!subscribeToMore || !playlistId || noContent || router.isFallback)
      return;
    // the try catch is to avoid error with next.js fast refresh
    // https://github.com/apollographql/apollo-client/issues/6437
    try {
      subscribeToMore({
        document: GET_UPDATED_LIVE_PLAYLIST,
        variables: {
          playlistId
        },
        updateQuery: (prev, { subscriptionData }) => {
          setRecoilLiveIdToUpdateState(subscriptionData.data.playlistLive.id);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, [subscribeToMore]);

  useEffect(() => {
    const dataRecevied = data && data[contentType];
    if (!dataRecevied) return;
    setContent(dataRecevied);
    setError(null);
  }, [data, router]);

  useEffect(() => {
    if (!apolloError) return;
    console.log('apolloError', apolloError);
    console.log('errorFromServer, ', errorFromServer);
    setError(apolloError?.graphQLErrors[0].extensions);
  }, [apolloError]);

  if (shouldBlockAccess) {
    return (
      <CustomError
        errorCode={t('error')}
        errorMessage={t('error-iframe-message')}
      />
    );
  }

  // while the next is fetching the data
  // show the loader
  if (router.isFallback) {
    return <LoaderSpin />;
  }
  // if playlist is empty
  if (playlistId && content?.content?.length === 0) {
    return <Custom404 />;
  }

  // if the content doesn't exist
  // and if the content is not protected (if the content is protected, no content is returned)
  if (noContent) {
    return (
      <CustomError
        errorCode={t('error-404')}
        errorMessage={t('error-404-message')}
      />
    );
  }

  // if the content is not published
  if (
    (mediaId || playlistId) &&
    content &&
    !content?.isPublished &&
    !router.query.allowUnpublished
  ) {
    return (
      <CustomError
        errorCode={t('error-404')}
        errorMessage={t('error-404-message')}
      />
    );
  }

  // If live status is Archived
  if (content?.status === 'Archived') {
    return (
      <CustomError
        errorCode={t('error-404')}
        errorMessage={t('error-404-message')}
      />
    );
  }

  if (error?.code === 'BAD_USER_INPUT') {
    return (
      <CustomError
        errorCode={t('error-404')}
        errorMessage={t('error-404-message')}
      />
    );
  }

  // hack to avoid the blinking privacy form when cookie or token is stored in cookie (client side)
  if (errorFromServer && !error && !content) {
    return <LoaderSpin />;
  }

  // if the content is protected
  if (error) {
    return (
      <>
        <Confidentiality
          errorData={error}
          contentId={contentId}
          contentType={contentType}
        />
        <Theme accentColor={error?.contentColor} />
      </>
    );
  }

  return (
    <>
      <Head
        media={mediaId ? content : null}
        live={liveId ? content : null}
        playlist={playlistId ? content : null}
        locale={router.locale}
      />
      <Main
        media={mediaId && contentType === 'media' ? content : null}
        live={liveId && contentType === 'live' ? content : null}
        playlist={playlistId ? content : null}
        isEmbed={isEmbed}
      />
      <Theme />
    </>
  );
};

export default Page;
