import { Layout, Tooltip, Typography } from 'antd';
import React from 'react';
import LogoFooter from '../../public/logoFooter.png';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

const { Footer: AntFooter } = Layout;

const Footer = function () {
    const screens = useBreakpoint();
  return (
    <AntFooter
      style={{ textAlign: 'center', background: 'rgb(12 56 112)', height: '195px' }}
      id="header"
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <img
          src={LogoFooter.src}
          alt="Logo"
          width={screens.sm ? 200 :100}
        />
        <h1
            style={{
                fontSize:screens.sm ? "40px" :'20px',
                fontWeight: "300",
                color: 'white',
            }}
        >Cérémonie des voeux 2023</h1>
      </div>
    </AntFooter>
  );
};

export default Footer;
